import config from '@/utils/config'
import RequestAdmin from '@/utils/request'

const urlDashboardLuminarias = `${config.URL_BASE}falla-lampara/dashboard`

export async function obtenerDatosDashboardLuminarias(body) {
  try {
    const request = new RequestAdmin()
    return request.executePostPutPatch(`${urlDashboardLuminarias}`, body, 'POST')
  } catch (err) {
    console.error('Error en Obtener Datos', err)
    return null
  }
}

// PARA MEJOR REFERENCIA VER LA DOCUMENTACIÓN DE apexcharts.com/vue-chart-demos/bar-charts

export const estilosDonas = {
  plotOptions: { // Corrected line with semicolon
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            fontSize: '2rem',
            fontFamily: 'Montserrat',
          },
          value: {
            fontSize: '1rem',
            fontFamily: 'Montserrat',
          },
          total: {
            show: true,
            fontSize: '1.5rem',
            label: 'Total',
            value: 0,
          },
        },
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
    fontSize: '14px',
    fontFamily: 'Montserrat',
  },
  dataLabels: {
    enabled: true,
  },
}

export function barraTicketsPorTecnicoUtils(datosTicketsPorTecnico, cantidadesPorUsuario) {
  return {
    series: [{
      name: 'Total Tickets Resueltos',
      data: cantidadesPorUsuario,
    }],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar',
      },
      colors: ['#666ee8', '#33b2df', '#d4526e', '#13d8aa', '#2b908f', '#f9a3a4', '#90ee7e'], // ESTO SOLO FUNCIONA SI ESTA DISTRIBUTED TRUE EN PLOTPTIONS
      plotOptions: {
        bar: {
          distributed: true, // ESTO ES IMPORTANTE SI SE QUIERE QUE LOS COLORES SE APLIQUEN
          horizontal: true,
          barHeight: '30%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: datosTicketsPorTecnico,
      },
    },
  }
}

export function barraTicketsPorReparacionesUtils(datosTicketsPorReparacion, cantidadesPorReparaciones) {
  return {
    series: [{
      name: 'Reparaciones Totales',
      data: cantidadesPorReparaciones,
    }],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar',
      },
      colors: ['#A5978B', '#2b908f', '#f48024', '#69d2e7', '#f9a3a4', '#90ee7e',
        '#33b2df', '#546E7A', '#d4526e', '#13d8aa',
      ],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          barHeight: '50%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: datosTicketsPorReparacion,
      },
    },
  }
}

export function barraTicketsPorFallasUtils(datosTicketsPorFalla, cantidadesPorFalla) {
  return {
    series: [{
      name: 'Total de Fallas',
      data: cantidadesPorFalla,
    }],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar',
      },
      colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
        '#f48024', '#69d2e7',
      ],
      plotOptions: {
        bar: {
          columnWidth: '70%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: datosTicketsPorFalla,
      },
    },
  }
}
