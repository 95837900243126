<template>
  <div>
    <b-overlay
      :show="show"
      opacity="0.9"
      variant="transparent"
      blur="0.80rem"
    >
      <template #overlay>
        <div
          style="margin-top: -65vh"
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <!-- FILTROS FECHAS Y CONTADORES -->
      <b-row>
        <b-col
          style="margin-bottom: -20px"
          cols="12"
        >
          <!-- FILTROS FECHAS -->
          <div>
            <b-row>
              <b-col
                cols="5"
              >
                <h5 class="text-primary text-uppercase">
                  Seleccione la fecha del reporte a generar
                </h5>
              </b-col>
              <h6 class="text-primary text-uppercase">
                Mes:
              </h6>
              <b-col
                style="margin-top: -10px"
                cols="3"
              >
                <v-select
                  v-model="mes"
                  :options="meses"
                  label="nombre"
                  @input="getAllData"
                />
              </b-col>
              <h6 class="text-primary text-uppercase">
                Año:
              </h6>
              <b-col
                style="margin-top: -10px"
                cols="3"
              >
                <v-select
                  v-model="año"
                  :options="años"
                  label="nombre"
                  @input="getAllData"
                />
                <br>
              </b-col>
            </b-row>
          </div>
          <hr style="margin-top: -10px">
          <!-- CONTADORES -->
          <div>
            <h5 class="text-primary text-uppercase">
              Conteo Mensual de Tickets por Estado
            </h5>
          </div>
          <div style="display: flex; flex-wrap: wrap; gap: 5px;">
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #ff5252">
                <center>
                  <div>INGRESADOS</div>
                  <div
                    class="contadoresGenerales"
                  >
                    {{ totalPorEstado('INGRESADA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #ffae26">
                <center>
                  REPARACIÓN
                  <div class="contadoresGenerales">
                    {{ totalPorEstado('REPARACIÓN') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #ffae26">
                <center>
                  GARANTÍA
                  <div class="contadoresGenerales">
                    {{ totalPorEstado('GARANTÍA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #57ba6a">
                <center>
                  PINTURA
                  <div class="contadoresGenerales">
                    {{ totalPorEstado('PINTURA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #006394">
                <center>
                  REPARADAS
                  <div class="contadoresGenerales">
                    {{ totalPorEstado('REPARADA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #006394">
                <center>
                  DESCARTADAS
                  <div class="contadoresGenerales">
                    {{ totalPorEstado('DESCARTADA') }}
                  </div>
                </center>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- GRAFICAS -->
      <b-row>
        <!-- GRAFICAS TIPOS DE LÁMPARA -->
        <b-col
          style="margin-bottom: -20px"
          cols="12"
        >
          <tabs-grafica-lamparas-estado
            :datos-graficas="datosLuminarias"
            :tipos-lamparas="tipos"
            tipo-de-datos="estadoModelo"
          />
        </b-col>
        <!-- GRAFICAS DE MANTENIMIENTOS POR ORIGEN -->
        <b-col
          style="margin-bottom: -20px"
          cols="4"
        >
          <donut-chart
            :key="1"
            :subtitle="'Información del mes en curso'"
            :title="'Mantenimientos por Origen'"
            :data="datosTicketsPorOrigen.data"
            :chart-options="datosTicketsPorOrigen.estilos"
          />
        </b-col>
        <b-col
          cols="4"
        >
          <donut-chart
            :key="2"
            :subtitle="'Información del mes en curso'"
            :title="'Cantidad de Luminarias Pintadas'"
            :data="datosTicketsPorPintura.data"
            :chart-options="datosTicketsPorPintura.estilos"
          />
        </b-col>
        <b-col
          style="margin-bottom: -20px"
          cols="4"
        >
          <donut-chart
            :key="3"
            :subtitle="'Información del mes en curso'"
            :title="'Mantenimientos por Estado'"
            :data="datosTicketsPorEstado.data"
            :chart-options="datosTicketsPorEstado.estilos"
          />
        </b-col>
        <b-col
          style="margin-top: -20px"
          cols="6"
        >
          <bar-chart
            :data="barraTicketsPorTecnico"
            :title="'Tickets Resueltos por Técnicos'"
            :subtitle="'Información del mes en curso'"
          />
        </b-col>
        <b-col
          style="margin-top: -20px"
          cols="6"
        >
          <bar-chart
            :data="datosBarrasTicketsPorReparaciones"
            :title="'Reparaciones de Laboratorio'"
            :subtitle="'Información del mes en curso'"
          />
        </b-col>
        <b-col
          style="margin-top: -20px"
          cols="12"
        >
          <bar-chart
            :data="datosBarraTicketsPorFallas"
            :title="'Cantidad de Ingresos por Falla'"
            :subtitle="'Información de los tipos de falla'"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- CONTADORES DE INVENTARIO -->
          <div>
            <h5 class="text-primary text-uppercase">
              Conteo de Disponibilidad en Inventario
            </h5>
          </div>
          <div style="display: flex; flex-wrap: wrap; gap: 5px;">
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #ff5252">
                <center>
                  <div>INGRESADOS</div>
                  <div
                    class="contadoresGenerales"
                  >
                    {{ totalBodega('INGRESADA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #ffae26">
                <center>
                  REPARACIÓN
                  <div class="contadoresGenerales">
                    {{ totalBodega('REPARACIÓN') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #ffae26">
                <center>
                  GARANTÍA
                  <div class="contadoresGenerales">
                    {{ totalBodega('GARANTÍA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #57ba6a">
                <center>
                  PINTURA
                  <div class="contadoresGenerales">
                    {{ totalBodega('PINTURA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #006394">
                <center>
                  REPARADAS
                  <div class="contadoresGenerales">
                    {{ totalBodega('REPARADA') }}
                  </div>
                </center>
              </b-card>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-card style="border: 2px solid #006394">
                <center>
                  DESCARTADAS
                  <div class="contadoresGenerales">
                    {{ totalBodega('DESCARTADA') }}
                  </div>
                </center>
              </b-card>
            </div>
          </div>
        </b-col>
        <!-- GRAFICAS TIPOS DE LÁMPARA -->
        <b-col
          style="margin-bottom: -20px"
          cols="12"
        >
          <tabs-grafica-lamparas-estado
            :datos-graficas="datosLuminarias"
            :tipos-lamparas="tipos"
            tipo-de-datos="luminariasBodega"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import {
  BRow, BCol, BOverlay, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { formatDate, getListadoMeses } from '@/utils/fechas'
import DonutChart from '@/components/charts/DonutChart.vue'
import BarChart from '@/components/charts/BarChart.vue'
import {
  barraTicketsPorFallasUtils,
  barraTicketsPorReparacionesUtils,
  barraTicketsPorTecnicoUtils,
  estilosDonas,
  obtenerDatosDashboardLuminarias,
} from '@/utils/bodega/dashboardUtils'
import TabsGraficaLamparasEstado from '@/components/bodega/tabsGraficaLamparasEstado.vue'
import { getlistado } from '@/utils/catalogos'
import { notificacionError } from '@/utils/mensajes'

export default {
  components: {
    TabsGraficaLamparasEstado,
    BarChart,
    DonutChart,
    BRow,
    BCol,
    BCard,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      show: true,
      mes: null,
      año: null,
      años: [],
      meses: [],
      datosLuminarias: {},
      datosTicketsPorOrigen: {
        data: [],
        options: {},
        counter: 0,
      },
      datosTicketsPorEstado: {
        data: [],
        options: {},
        counter: 0,
      },
      barraTicketsPorTecnico: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
        },
      },
      datosBarrasTicketsPorReparaciones: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
        },
      },
      datosBarraTicketsPorFallas: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
        },
      },
      datosTicketsPorPintura: {
        data: [],
        options: {},
        counter: 0,
      },
      tipos: [],
      stylesDonas: {},
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async mounted() {
    this.años = getListadoMeses(2)
    this.meses = getListadoMeses(1)
    this.año = this.años.find(a => Number(a.id) === (new Date().getFullYear()))
    this.mes = this.meses.find(a => Number(a.id) === (new Date().getMonth() + 1))
    this.stylesDonas = estilosDonas
    try {
      await this.getAllData()
      this.donaTicketsPorOrigen()
      this.donaTicketsPorEstado()
      this.barraTicketsResueltosPorTecnicos()
      this.barraTicketsReparaciones()
      this.barrasTicketsPorFallas()
      this.donaTicketsPorPintura()
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async getAllData() {
      this.show = true
      try {
        if (!this.año || !this.mes) {
          notificacionError('Año o mes no definidos')
        }
        const fechaFin = new Date(`${this.año.id}-${this.mes.id}-${this.mes.dia}`)
        const fechaInicio = new Date(`${this.año.id}-${this.mes.id}-01`)
        const data = {
          companyId: this.usuario.company.id,
          fechaInicio: formatDate(fechaInicio),
          fechaFin: formatDate(fechaFin),
        }
        this.datosLuminarias = await obtenerDatosDashboardLuminarias(data)
        this.tipos = await getlistado(9, 'Tipo de Lámparas', false, this.usuario)
      } catch (error) {
        console.error(error)
        notificacionError('Ha ocurrido un error obteniendo los datos')
      } finally {
        this.show = false
      }
    },
    donaTicketsPorOrigen() {
      try {
        // Mapeo de los destinos a sus nombres
        const destinoMap = {
          1: 'REPARACIÓN',
          2: 'GARANTÍA',
          3: 'DESCARTADAS',
        }
        // Inicializar las cantidades en 0
        const cantidades = {
          REPARACIÓN: 0,
          GARANTÍA: 0,
          DESCARTADAS: 0,
        }
        let total = 0
        this.datosLuminarias.conteoDestino.forEach(item => {
          const nombre = destinoMap[item.destino]
          if (nombre) {
            cantidades[nombre] += item.cantidad
            total += item.cantidad
          }
        })
        const labels = ['REPARACIÓN', 'GARANTÍA', 'DESCARTADAS']
        const data = labels.map(label => cantidades[label])
        this.datosTicketsPorOrigen = {
          data,
          estilos: {
            labels,
            legend: this.stylesDonas.legend,
            dataLabels: this.stylesDonas.dataLabels,
            plotOptions: this.stylesDonas.plotOptions,
            total: {
              show: true,
              fontSize: '2rem',
              label: 'Total',
              value: total,
            },
          },
        }
      } catch (error) {
        console.error(error)
      }
    },
    donaTicketsPorEstado() {
      try {
        // Inicializar las cantidades en 0
        const cantidades = {
          INGRESADA: 0,
          REPARACIÓN: 0,
          GARANTÍA: 0,
          PINTURA: 0,
          REPARADA: 0,
          DESCARTADA: 0,
        }
        // Acumular las cantidades
        let total = 0
        this.datosLuminarias.estadoModelo.forEach(item => {
          // eslint-disable-next-line no-prototype-builtins
          if (cantidades.hasOwnProperty(item.estado)) {
            cantidades[item.estado] += item.cantidad
            total += item.cantidad
          }
        })
        // Construir los datos y labels en el orden deseado
        const labels = ['INGRESADA', 'REPARACIÓN', 'GARANTÍA', 'PINTURA', 'REPARADA', 'DESCARTADA']
        const data = labels.map(label => cantidades[label])
        this.datosTicketsPorEstado = {
          data,
          estilos: {
            labels,
            legend: this.stylesDonas.legend,
            dataLabels: this.stylesDonas.dataLabels,
            plotOptions: this.stylesDonas.plotOptions,
            total: {
              show: true,
              fontSize: '2rem',
              label: 'Total',
              value: total,
            },
          },
        }
      } catch (error) {
        console.error(error)
      }
    },
    donaTicketsPorPintura() {
      try {
        // Inicializar las etiquetas y los datos
        const labels = []
        const data = []

        // Recorrer el array y llenar las etiquetas y datos
        let total = 0
        this.datosLuminarias.conteoPintura.forEach(item => {
          labels.push(item.pintada) // "SI" o "NO"
          data.push(item.cantidad) // La cantidad correspondiente
          total += item.cantidad
        })

        // Asignar los datos y estilos para la dona
        this.datosTicketsPorPintura = {
          data,
          estilos: {
            labels,
            legend: this.stylesDonas.legend,
            dataLabels: this.stylesDonas.dataLabels,
            plotOptions: this.stylesDonas.plotOptions,
            total: {
              show: true,
              fontSize: '2rem',
              label: 'Total',
              value: total,
            },
          },
        }
      } catch (error) {
        console.error(error)
      }
    },
    barraTicketsResueltosPorTecnicos() {
      try {
        const datosTicketsPorTecnico = this.datosLuminarias?.reparacionesTecnico || []

        if (!Array.isArray(datosTicketsPorTecnico) || datosTicketsPorTecnico.length === 0) {
          console.warn('No se encontraron datos de reparaciones por técnico.')
          this.barraTicketsPorTecnico = barraTicketsPorTecnicoUtils([], [])
          return
        }

        const nombresReparaciones = []
        const cantidadesPorReparacion = []

        // eslint-disable-next-line no-restricted-syntax
        for (const reparacion of datosTicketsPorTecnico) {
          const nombreReparacion = reparacion.usuario || 'Usuario Técnico'
          const { cantidad } = reparacion

          if (!nombresReparaciones.includes(nombreReparacion)) {
            nombresReparaciones.push(nombreReparacion)
            cantidadesPorReparacion.push(0)
          }

          const indiceTecnico = nombresReparaciones.indexOf(nombreReparacion)
          cantidadesPorReparacion[indiceTecnico] += cantidad
        }

        this.barraTicketsPorTecnico = barraTicketsPorTecnicoUtils(nombresReparaciones, cantidadesPorReparacion)
      } catch (error) {
        console.error('Error al generar los datos para la gráfica de tickets resueltos por técnicos:', error)
        this.barraTicketsPorTecnico = barraTicketsPorTecnicoUtils([], [])
      }
    },
    barraTicketsReparaciones() {
      try {
        const datosTicketsPorReparaciones = this.datosLuminarias.tiposReparaciones
        const nombresReparaciones = []
        const cantidadesPorReparacion = []

        // eslint-disable-next-line no-restricted-syntax
        for (const reparacion of datosTicketsPorReparaciones) {
          const nombreReparacion = reparacion.reparacion
          const { cantidad } = reparacion

          if (!nombresReparaciones.includes(nombreReparacion)) {
            nombresReparaciones.push(nombreReparacion)
            cantidadesPorReparacion.push(0)
          }

          const indiceReparacion = nombresReparaciones.indexOf(nombreReparacion)
          cantidadesPorReparacion[indiceReparacion] += cantidad
        }
        this.datosBarrasTicketsPorReparaciones = barraTicketsPorReparacionesUtils(nombresReparaciones, cantidadesPorReparacion)
      } catch (error) {
        console.error(error)
      }
    },
    barrasTicketsPorFallas() {
      try {
        const datosTicketsPorFalla = this.datosLuminarias.tiposFallas
        const nombresFallas = []
        const cantidadesPorFalla = []

        // eslint-disable-next-line no-restricted-syntax
        for (const reparacion of datosTicketsPorFalla) {
          const nombreFalla = reparacion.falla
          const { cantidad } = reparacion

          if (!nombresFallas.includes(nombreFalla)) {
            nombresFallas.push(nombreFalla)
            cantidadesPorFalla.push(0)
          }

          const indiceFalla = nombresFallas.indexOf(nombreFalla)
          cantidadesPorFalla[indiceFalla] += cantidad
        }
        this.datosBarraTicketsPorFallas = barraTicketsPorFallasUtils(nombresFallas, cantidadesPorFalla)
      } catch (error) {
        console.error(error)
      }
    },
    totalPorEstado(estado) {
      if (!this.datosLuminarias.estadoModelo || !Array.isArray(this.datosLuminarias.estadoModelo)) {
        return 0 // Devolver cero si estadoModelo no está definido correctamente
      }
      return this.datosLuminarias.estadoModelo
        .filter(item => item.estado === estado)
        .reduce((total, item) => total + item.cantidad, 0)
    },
    totalBodega(estado) {
      if (!this.datosLuminarias.luminariasBodega || !Array.isArray(this.datosLuminarias.luminariasBodega)) {
        return 0 // Devolver cero si estadoModelo no está definido correctamente
      }
      return this.datosLuminarias.luminariasBodega
        .filter(item => item.estado === estado)
        .reduce((total, item) => total + item.cantidad, 0)
    },
  },
}
</script>
<style scoped>
.contadoresGenerales {
  font-weight: bold;
  font-size: x-large;
  margin-top: -5px;
  margin-bottom: -10px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
