<template>
  <b-card>
    <b-card-title class-name="mb-1">
      {{ title }}
    </b-card-title>
    <b-card-sub-title class-name="mb-2">
      {{ subtitle }}
    </b-card-sub-title>
    <vue-apex-charts
      type="bar"
      :height="height"
      :options="data.chartOptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    height: {
      type: Number,
      required: false,
      default: 350,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Object,
      required: false,
      default() {
        return {
          chartOptions: {},
          series: [],
        }
      },
    },
  },
}
</script>
