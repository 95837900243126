<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ title }}
    </b-card-title>
    <b-card-sub-title class="mb-2">
      {{ subtitle }}
    </b-card-sub-title>

    <vue-apex-charts
      v-if="chartOptions && data"
      type="donut"
      :options="chartOptions"
      :series="data"
      :height="height"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    height: {
      type: Number,
      required: false,
      default: 350,
    },
    data: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    chartOptions: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
