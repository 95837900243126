<template>
  <div>
    <b-card
      class="body-card"
      title="Conteo Mensual De Tickets Por Modelo De Lámpara"
      sub-title="Hacer clic en cada modelo para ver su detalle"
    >
      <b-row class="d-flex justify-content-center">
        <b-col
          v-for="(tipo, index) in tiposLamparas"
          :key="index"
          cols="4"
          md="2"
        >
          <div
            @click="pasarDatos(tipo)"
          >
            <b-card
              :class="['card-body-tipos', { 'card-seleccionado': cardSeleccionado === tipo.nombre }]"
            >
              <center>
                <div>
                  <b-badge
                    class="badge-tipos"
                    :variant="getColor(index)"
                  >
                    <Icon
                      class="icono-tipos"
                      :icon="getIcono(tipo.nombre)"
                    />
                  </b-badge>
                </div>
                <div class="cantidad-total-tipos">
                  {{ totalPorModelo(tipo.nombre) }}
                </div>
                <div>
                  {{ tipo.nombre }}
                </div>
              </center>
            </b-card>
          </div>
        </b-col>
        <b-col cols="12">
          <hr style="margin-top: -8px; margin-bottom: 30px">
        </b-col>
        <b-col cols="12">
          <StatisticsChart
            :datos-modelo-estados="datosGraficas"
            :modelo-seleccionado="modeloSeleccionado"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BBadge,
} from 'bootstrap-vue'
import StatisticsChart from '@/components/charts/StatisticsChart.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Icon,
    StatisticsChart,
    BRow,
    BCol,
    BCard,
    BBadge,
  },
  props: {
    datosGraficas: {
      type: Object,
      default: () => {},
    },
    tiposLamparas: {
      type: Array,
      default: () => [],
    },
    tipoDeDatos: {
      type: String,
      required: true, // "estadoModelo" o "luminariasBodega"
    },
  },
  data() {
    return {
      tipoSeleccionado: null,
      modeloSeleccionado: null,
      cardSeleccionado: null,
    }
  },
  watch: {
    tiposLamparas(newVal) {
      if (newVal.length > 0) {
        this.pasarDatos(newVal[0])
      }
    },
  },
  created() {
    this.inicializarDatos()
  },
  methods: {
    totalPorModelo(modeloNombre) {
      const datos = this.datosGraficas[this.tipoDeDatos] || []

      if (!Array.isArray(datos)) {
        return 0
      }

      // Filtrar y sumar cantidades del modelo especificado
      const total = datos
        .filter(item => item.modelo === modeloNombre)
        .reduce((acc, item) => acc + item.cantidad, 0)

      return total
    },
    pasarDatos(tipo) {
      this.cardSeleccionado = tipo.nombre
      const datos = this.datosGraficas[this.tipoDeDatos] || []
      // Filtrar y agrupar los datos por estado para el modelo seleccionado
      const modeloEstados = datos
        .filter(item => item.modelo === tipo.nombre)
        .reduce((acc, item) => {
          if (!acc[item.estado]) {
            acc[item.estado] = 0
          }
          acc[item.estado] += item.cantidad
          return acc
        }, {})

      // Convertir el objeto en un array de objetos para pasar a StatisticsChart
      this.modeloSeleccionado = Object.keys(modeloEstados).map(estado => ({
        estado,
        modelo: tipo.nombre,
        cantidad: modeloEstados[estado],
      }))
    },
    getColor(index) {
      const colors = ['light-info', 'light-primary', 'light-success', 'light-secondary', 'light-warning', 'light-danger', 'light-dark']
      return colors[index % colors.length]
    },
    getIcono(nombre) {
      return this.cardSeleccionado === nombre ? 'material-symbols:wall-lamp-sharp' : 'material-symbols:wall-lamp-outline-sharp'
    },
    inicializarDatos() {
      if (this.tiposLamparas.length > 0) {
        this.pasarDatos(this.tiposLamparas[0])
      }
    },
  },
}
</script>
<style scoped>
.card-body-tipos {
  position: relative;
  height: 200px;
  border-radius: 30px;
  overflow: hidden;
  transition: background-color 0.8s ease; /* Transición suave del color de fondo */
}
.card-seleccionado {
  background-color: rgba(169, 169, 169, 0.2); /* Color con opacidad */
}
.card-body-tipos:hover {
  background-color: rgba(169, 169, 169, 0.1); /* Color con opacidad al pasar el ratón */
}
.badge-tipos {
  width: 80px;
  height: 60px;
  border-radius: 30px;
  position: relative; /* Asegura que estén encima del overlay */
  z-index: 2; /* Asegura que los badges estén encima del overlay */
}
.icono-tipos {
  width: 50px;
  height: 50px;
  mask-size: 100% 100%;
  position: relative; /* Asegura que estén encima del overlay */
  z-index: 2; /* Asegura que los iconos estén encima del overlay */
}
.cantidad-total-tipos {
  font-weight: bold;
  font-size: x-large;
  position: relative; /* Asegura que estén encima del overlay */
  z-index: 2; /* Asegura que el texto esté encima del overlay */
}
</style>
