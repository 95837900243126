<template>
  <b-row class="card-barras-conteo">
    <b-col v-if="!modeloSeleccionado || modeloSeleccionado.length === 0">
      <strong style="font-size: large; color: orange">
        <center>😐 NO HAY TICKETS CON ESTE MODELO DE LÁMPARA 😐</center>
      </strong>
    </b-col>
    <b-col
      v-for="(data, i) in modeloSeleccionado"
      v-else
      :key="i"
      cols="4"
      class="mb-1"
    >
      <strong>{{ data.estado }}</strong>
      ({{ data.cantidad }} / {{ totalMax }})
      <b-progress
        :max="totalMax"
        :value="data.cantidad"
        height="15px"
        animated
        :variant="getVariant(data.estado)"
      />
    </b-col>
  </b-row>
</template>
<script>
import {
  BProgress, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BProgress,
  },
  props: {
    datosModeloEstados: {
      type: Object,
      default: () => {},
    },
    modeloSeleccionado: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      variantesBarrasFondo: {
        REPARACIÓN: 'progress-bar-info',
        DESCARTE: 'progress-bar-danger',
        PINTURA: 'progress-bar-warning',
        'LISTAS PARA DESPACHO': 'progress-bar-success',
        DESPACHADAS: 'progress-bar-primary',
      },
    }
  },
  computed: {
    totalMax() {
      return this.modeloSeleccionado.reduce((acc, item) => acc + item.cantidad, 0)
    },
  },
  methods: {
    getClass(nombre) {
      return this.variantesBarrasFondo[nombre.toUpperCase()] || 'progress-bar-primary'
    },
    getVariant(estado) {
      // Define la variante según el estado si es necesario
      const variantes = {
        INGRESADA: 'secondary',
        REPARACIÓN: 'info',
        GARANTÍA: 'warning',
        PINTURA: 'success',
        DESCARTADA: 'danger',
        REPARADA: 'primary',
      }
      return variantes[estado] || 'secondary'
    },
  },
}
</script>
<style scoped>
.card-barras-conteo {
  margin-top: -15px;
}
</style>
